import React from "react";
import styled from "styled-components";
import Logo from "../assets/lowcodefy-logo.png";

// Container principal da página
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

// Estilo para cada seção
const Section = styled.section`
  width: 100%;
  padding: 4rem 0;
  text-align: center;
  background: ${({ bgGradient }) => bgGradient || "white"};
  color: ${({ textColor }) => textColor || "#333"};
`;

// Estilos específicos da header
const HeaderSection = styled(Section)`
  background: linear-gradient(135deg, #7b2ff7, #00d2ff); /* Aplicando o gradiente de roxo e azul */
  color: white; /* Garantindo que o texto seja branco */
`;

// Estilo da logo no header
const LogoImage = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  height: 60px; /* Ajuste o tamanho conforme necessário */
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: ${({ color }) => color || "#333"};
  margin-bottom: 2rem;
`;

const TechnologiesGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
`;

const TechnologyBlock = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 250px;
  text-align: center;
  transition: transform 0.2s;
  &:hover {
    transform: translateY(-10px);
  }
`;

const PortfolioGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
`;

const PortfolioItem = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  width: 300px;
  text-align: center;
  transition: transform 0.2s;
  &:hover {
    transform: translateY(-10px);
  }
`;

const ClientList = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
`;

const ClientLogo = styled.img`
  height: 80px;
  margin: 1rem;
`;

const ContactButton = styled.button`
  padding: 1rem 2rem;
  background-color: #00d2ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    background-color: #00b0e0;
  }
`;

const AboutText = styled.p`
  font-size: 1.5rem;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
`;

function Home() {
  return (
    <HomeContainer>
      {/* Seção de Introdução (Header) */}
      <HeaderSection>
        <LogoImage src={Logo} alt="Lowcodefy Logo" />
        <Title>Lowcodefy</Title>
        <Subtitle>Automating the Future with AI and Automation</Subtitle>
      </HeaderSection>

      {/* Seção Quem Somos Nós */}
      <Section>
        <SectionTitle color="#7b2ff7">Quem Somos Nós</SectionTitle>
        <AboutText>
          Nós somos a Lowcodefy, uma empresa dedicada a desenvolver as melhores ferramentas para o futuro. Acreditamos no poder da IA e nas transformações que ela pode trazer para o seu negócio e para o mundo. Nosso objetivo é criar soluções que melhorem processos, automatizem tarefas e abram novas possibilidades no mundo digital.
        </AboutText>
      </Section>

      {/* Seção de Tecnologias */}
      <Section>
        <SectionTitle color="#7b2ff7">Technologies We Use</SectionTitle>
        <TechnologiesGrid>
          <TechnologyBlock>
            <h3>Artificial Intelligence</h3>
            <p>Implementação de soluções avançadas de IA para otimização de processos.</p>
          </TechnologyBlock>
          <TechnologyBlock>
            <h3>Machine Learning</h3>
            <p>Modelos de aprendizado de máquina personalizados para suas necessidades.</p>
          </TechnologyBlock>
          <TechnologyBlock>
            <h3>Cloud Computing</h3>
            <p>Infraestrutura escalável e segura na nuvem para suas soluções.</p>
          </TechnologyBlock>
          <TechnologyBlock>
            <h3>Robotic Process Automation</h3>
            <p>Automatização de fluxos de trabalho repetitivos e manuais.</p>
          </TechnologyBlock>
        </TechnologiesGrid>
      </Section>

      {/* Seção de Portfólio */}
      <Section bgGradient="#f9f9f9">
        <SectionTitle color="#00d2ff">Our Portfolio</SectionTitle>
        <PortfolioGrid>
          <PortfolioItem>
            <h3>Automação de Processos Bancários</h3>
            <p>Solução de automação para uma grande instituição financeira.</p>
          </PortfolioItem>
          <PortfolioItem>
            <h3>IA para E-commerce</h3>
            <p>Otimização de recomendações de produtos usando aprendizado de máquina.</p>
          </PortfolioItem>
          <PortfolioItem>
            <h3>Automatização de Suporte ao Cliente</h3>
            <p>Chatbots avançados com integração em múltiplos canais.</p>
          </PortfolioItem>
        </PortfolioGrid>
      </Section>

      {/* Seção de Clientes */}
      <Section>
        <SectionTitle color="#7b2ff7">Our Clients</SectionTitle>
        <ClientList>
          {/* Adicione aqui os logos de clientes */}
          <ClientLogo src="https://via.placeholder.com/150x80?text=Cliente+1" alt="Cliente 1" />
          <ClientLogo src="https://via.placeholder.com/150x80?text=Cliente+2" alt="Cliente 2" />
          <ClientLogo src="https://via.placeholder.com/150x80?text=Cliente+3" alt="Cliente 3" />
        </ClientList>
      </Section>

      {/* Seção de Contato */}
      <Section bgGradient="linear-gradient(135deg, #00d2ff, #7b2ff7)">
        <SectionTitle color="white">Let's Work Together!</SectionTitle>
        <ContactButton>Contact Us</ContactButton>
      </Section>
    </HomeContainer>
  );
}

export default Home;
