import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logout } from "../services/authService";

const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const AdminTitle = styled.h1`
  font-size: 2rem;
  color: #333;
`;

const LogoutButton = styled.button`
  margin: 1rem;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  background-color: #ff4c4c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #cc3939;
  }
`;

function Admin() {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <AdminContainer>
      <AdminTitle>Admin Panel</AdminTitle>
      <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
    </AdminContainer>
  );
}

export default Admin;
