import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../services/authService";

// Componente de rota privada
const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/" />;
};

export default PrivateRoute;
