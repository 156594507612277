import axios from "axios";

const API_URL = "https://seu-backend.com/api"; // Substitua pela URL do backend

// Função de login
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/login`, { email, password });
    if (response.data.token) {
      localStorage.setItem("token", response.data.token);
    }
    return response.data;
  } catch (error) {
    console.error("Erro ao fazer login", error);
    throw error;
  }
};

// Função para verificar se o usuário está autenticado
export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return token != null;
};

// Função para obter o token JWT
export const getToken = () => {
  return localStorage.getItem("token");
};

// Função para logout
export const logout = () => {
  localStorage.removeItem("token");
};
